export enum StorageKeys {
  PRIMARY_COLOR = 'primaryColor',
  SERVICE_NAME = 'service',
  SERVICE_TYPE = 'serviceType',
  SERVICE_ID = 'serviceId',
  VENUE_ID = 'venueId',
  VENUE_NAME = 'venueName',
  BASKET_DATA = 'basketData',
  REORDER_DATA = 'reorderData',
  DRAFT_ORDER_ID = 'draftOrderId',
  CURRENCY = 'currency',
  AUTH_TOKENS = 'tokens',
  VENUE_GROUP_ID = 'venueGroupId',
  CUSTOMER_PHONE = 'customerPhone',
  CUSTOMER_NAME = 'customerName',
  CUSTOMER_ADDRESS = 'customerAddress',
  DIAL_CODE = 'dialCode',
  VENUE_TIME_ZONE = 'venueTimeZone',
  ORDER_ID = 'orderId',
  PHONE = 'phone',
  ADDRESS = 'address',
  REFERRER_ID = 'referrer_id',
  IS_INITIAL_OPEN = 'is_initial_open',
  COUNTRY_CODES = 'country_codes',
  DISCOUNT_AMOUNT = 'discount_amount',
  DISCOUNT_DATA = 'discount_data',
  COUPON = 'coupon',
  VOUCHER_ID = 'voucher_id',
  VOUCHER_VALUE = 'voucher_value',
  VOUCHER_AVAILABLE_BALANCE = 'voucher_available_balance',
}

class StorageService {
  private store: Record<string, string> = {};

  set(key: StorageKeys, value: string): boolean {
    try {
      this.store[key] = value;
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  }

  get(key: StorageKeys): string | null {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return this.store[key];
    }
  }

  remove(key: StorageKeys): boolean {
    try {
      delete this.store[key];
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }
}

class SessionStorageService {
  private store: Record<string, string> = {};

  set(key: StorageKeys, value: string): boolean {
    try {
      this.store[key] = value;
      sessionStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  }

  get(key: StorageKeys): string | null {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      return this.store[key];
    }
  }
}

export const storage = new StorageService();

export const storageSession = new SessionStorageService();
